import "./styles/app.css";
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import AuthDataProvider from "./components/common/AuthDataProvider";
import MainComponent from "./components/MainComponent";
//import { CAlert } from "@coreui/react";

const Lottery = props => {
    return (
        <BrowserRouter>
            <AuthDataProvider>
                <MainComponent />
                {/*<CAlert stack={{ limit: 3 }} timeout={3000} position="top-right" effect="slide" offset={65} />*/}
            </AuthDataProvider>
        </BrowserRouter>
    )
}

export default Lottery;