import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';
import { ACCESS_TOKEN } from "../config/config";
import { useAuthDataContext } from "./common/AuthDataProvider";
import routes from '../routes';
import PrivateRoute from './common/PrivateRoute';

const MainComponent = (props) => {

    const auth = useAuthDataContext();
    const token = localStorage.getItem(ACCESS_TOKEN);

    useEffect(() => {
        auth.login(token);
    });

    const loading = (
        <div className="pt-3 text-center">
            <div className="sk-spinner sk-spinner-pulse"></div>
        </div>
    )

    console.log("authenticated", auth.user !== null);    
    
    return (
        <React.Suspense fallback={loading}>
            <Switch>
                {routes.map((route, idx) => {
                    const authenticated = token !== undefined && token !== null;
                    if(route.component && route.private) {
                        return <PrivateRoute
                        authenticated={authenticated}
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        component={route.component}
                        render={props => (
                            <route.component {...props} />
                        )} />
                    } else if (route.component) {
                        return <Route
                        authenticated={authenticated}
                        key={idx}
                        path={route.path}
                        exact={route.exact}
                        name={route.name}
                        render={props => (
                            <route.component {...props} />
                        )} />
                    }
                    return '';
                })}
            </Switch>
        </React.Suspense>
    );
}

export default MainComponent;