
export const backend_server = process.env.REACT_APP_BACKEND_SERVER;

export const API_BASE_URL = backend_server;

export const ACCESS_TOKEN = "accessToken";

export const OAUTH2_REDIRECT_URI = process.env.REACT_APP_OAUTH2_REDIRECT_URI;
//export const OAUTH2_REDIRECT_URI = "https://lotteri.finstadlille.no/oauth2/redirect";
//export const OAUTH2_REDIRECT_URI = "http://192.168.86.34:3000/oauth2/redirect";
//export const OAUTH2_REDIRECT_URI = "http://localhost:3000/oauth2/redirect";

export const GOOGLE_AUTH_URL = API_BASE_URL + "/oauth2/authorize/google?redirect_uri=" + OAUTH2_REDIRECT_URI;


/*export const ca_cert = `-----BEGIN CERTIFICATE----- 
MIIDgjCCAmqgAwIBAgIJAMCL4cQxK2qQMA0GCSqGSIb3DQEBCwUAMG4xEDAOBgNV
BAYTB1Vua25vd24xEDAOBgNVBAgTB1Vua25vd24xEDAOBgNVBAcTB1Vua25vd24x
EDAOBgNVBAoTB1Vua25vd24xEDAOBgNVBAsTB1Vua25vd24xEjAQBgNVBAMTCWxv
Y2FsaG9zdDAgFw0yMTA3MjYxMjI5MjFaGA8yMTIxMDcwMjEyMjkyMVowbjEQMA4G
A1UEBhMHVW5rbm93bjEQMA4GA1UECBMHVW5rbm93bjEQMA4GA1UEBxMHVW5rbm93
bjEQMA4GA1UEChMHVW5rbm93bjEQMA4GA1UECxMHVW5rbm93bjESMBAGA1UEAxMJ
bG9jYWxob3N0MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAhAmzrVrL
6/2Bbcp1gbjxQ+gwf31jQqHm9ETtyQBTRCrgiC1xh0Kw2xyaD646IK3sUoM813jF
oEMKNfiYRcNPGQ2szgL337rX48tNfvhxyLfWotYtDfnn9pa5D7tMr/GhauZraIzN
UW6wdKXoVFqqOd6VSxe4UlQl/EAx+hQMGxke3FjXeRL7FsD1suWgfXUZoj2/RhxE
4JaQqMIBM40vA10HMnzwd9gqbHkk/y0AMramTnTTpTqBK2RRzd4RlMiN0OOEiSf4
9UGfs/ncM75Ee8moFogFuqroHcDwGh/BhQ7PodfSNBaQCVc6zXae2ELmO4Lx90uY
1KPxKec/TZfYZQIDAQABoyEwHzAdBgNVHQ4EFgQUoGKUznDVxPtiQUgLE2CRngn1
fkowDQYJKoZIhvcNAQELBQADggEBAG1gDcxW7Zh/O0F5WqhufNxNwD1lNxFwy9lB
mmfMnSEe8/ccJdekbCNKJ5zNBFLj0ob9lXEhmD794FRNMcdYhriy1+kSx3+ppLqj
2IUt3CjkVJNJoFBGBHeu3xCgoSYhmF9lU/q6uFWybpyU99Mkrutk/szj+RUMXZLr
LgmHB3c+RBh/grt0LgrxL/WBan39smYhqT9i/oBqoi22J1XY49we0WQJpSb8xd6X
EczSHtPs7fRecH+C+ixBjXAJh2iNUaOhKAE55MD4YDIaAXwWim7ncRZMCV9EorfG
/DruEdFWOa0P+8nDx6xXHgjSqLAlJtTs08AV2xXG80d5Pb3AHAM=
-----END CERTIFICATE-----
`;*/