import React from 'react';

const Login = React.lazy(() => import('./components/login/Login'));
const LotteryList = React.lazy(() => import('./components/LotteryList'));
const UserList = React.lazy(() => import('./components/UserList'));
const CreateLottery = React.lazy(() => import('./components/CreateLottery'));
const LotteryStatistics = React.lazy(() => import('./components/LotteryStatistics'));
const LotteryDraw = React.lazy(() => import('./components/LotteryDraw'));
const OAuth2RedirectHandler = React.lazy(() => import('./components/oauth2/OAuth2RedirectHandler'));


const routes = [
  { path: '/', exact: true, name: 'Login', component: Login, private: false },
  { path: '/lottery/list', name: 'Lotteries', component: LotteryList, private: true },
  { path: '/lottery/create', name: 'Create', component: CreateLottery, private: true },
  { path: '/user/list', name: 'Users', component: UserList, private: true },
  { path: '/statistics', name: 'Create', component: LotteryStatistics, private: true },
  { path: '/lottery/:id', name: '', component: LotteryDraw, private: true },
  { path: '/oauth2/redirect', name: 'Login redirect', component: OAuth2RedirectHandler, private: false },
  // default route at the bottom
  { path: '/*', name: '', component: Login, private: false },
];

export default routes;
