import React, { createContext, useState, useContext } from "react";
import { ACCESS_TOKEN } from "../../config/config";


const noop = () => {};
const AuthDataContext = createContext({user: null, login: noop, logout: noop});

const AuthDataProvider = props => {
    const [user, setUser] = useState(null);
    const auth = {
        user,
        login: user => setUser(user),
        logout: () => {
          setUser(null);
          localStorage.removeItem(ACCESS_TOKEN);
        }
    };

  return <AuthDataContext.Provider value={auth} {...props} />;
};

export default AuthDataProvider;

export const useAuthDataContext = () => useContext(AuthDataContext);